.Header {
  position: relative;
  height: 5rem;
  max-width: 1640px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.Header--Logo {
  position: absolute;
}

.Header--help {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.Header--help .Icon--wrapper {
  border: 5px solid var(--white);
  background-color: var(--blue);
}
