.Icon--sm {
  width: 1rem;
  height: 1rem;
}

.Icon--md {
  width: 1.4rem;
  height: 1.4rem;
}

.Icon--lg {
  width: 1.8rem;
  height: 1.8rem;
}

.Icon--border {
  box-sizing: content-box;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  background-color: none;
  border-radius: 50%;
}

.Icon--border--size-sm {
  padding: 0.3rem;
}

.Icon--border--size-md {
  padding: 0.5rem;
}

.Icon--border--size-lg {
  padding: 0.8rem;
}

.Icon--Wrapper {
  display: inline-block;
  border: 1px solid;
}

.Icon--border--color-aqua {
  border-color: var(--aqua);
}
.Icon--border--color-blue {
  border-color: var(--blue);
}
.Icon--border--color-white {
  border-color: var(--white);
}
.Icon--border--color-green {
  background-color: var(--green);
}
.Icon--border--color-yellow {
  background-color: var(--yellow);
}
.Icon--border--color-grey {
  background-color: var(--grey);
}

.Icon--border--style-thick {
  border-width: 5px;
}

.Icon--background--color-aqua {
  background-color: var(--aqua);
}
.Icon--background--color-blue {
  background-color: var(--blue);
}
.Icon--background--color-white {
  background-color: var(--white);
}
.Icon--background--color-green {
  background-color: var(--green);
}
.Icon--background--color-yellow {
  background-color: var(--yellow);
}
.Icon--background--color-grey {
  background-color: var(--grey);
}
