.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  text-transform: uppercase;
  font-weight: 900;
}

.disabled {
  cursor: default;
}

/* .icon {
  height: 3rem;
  width: 3rem;
} */

.squared {
  border-radius: 0.5rem;
}

.rounded {
  padding: 1rem 2rem;
  border-radius: 2rem;
}

.primary {
  background: var(--yellow) 0% 0% no-repeat padding-box;
  color: var(--blue);
}

.secondary {
  background: var(--lightBlue) 0% 0% no-repeat padding-box;
  color: var(--blue);
}

.tertiary {
  background: var(--grey) 0% 0% no-repeat padding-box;
  color: var(--white);
}

.dark {
  background: var(--blue) 0% 0% no-repeat padding-box;
  color: var(--white);
}

.icon.primary {
  box-shadow: 0px 3px 0.5rem rgba(247, 176, 107, 0.5);
}

.icon.secondary {
  box-shadow: 0px 3px 0.5rem rgba(104, 211, 217, 0.5);
}

.icon.tertiary {
  box-shadow: 0px 3px 0.5rem rgba(143, 157, 176, 0.4);
}
