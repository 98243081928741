.SiteMenu {
  position: fixed;
  right: 0;
  z-index: 100;
  width: 16rem;
}

.SiteMenu--activator {
  position: absolute;
  right: 1rem;
}

.SiteMenu--LogoutButton {
  display: block;
  margin: 0 auto;
}

.SiteMenu--Icon {
  transform: translateY(3px);
  display: inline-block;
  margin-right: 1rem;
}
