.Footer {
  width: 100%;
  min-height: 3rem;
  background-color: var(--blue);
  color: var(--white);
  margin-top: 1rem;
  padding: 1rem;
}

.Footer--content {
  display: flex;
  flex-flow: wrap;
  height: 100%;
  max-width: 1640px;
  width: 100%;
  margin: 0 auto;
}

.Footer--content > * {
  line-height: 1rem;
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
}

.Footer--content > *:not(:last-child) {
  margin-right: 1rem;
}

.Footer--content > *:last-child {
  margin-left: auto;
}
