.MenuItem--ul {
  list-style: none;
}

.MenuItem--li {
  align-items: center;
  display: flex;
}

.MenuItem--a {
  display: flex;
  flex: 1;
  text-decoration: inherit;
  color: inherit;
  font-weight: 500;
  padding: 1.25rem 1rem;
}
