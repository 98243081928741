.Card--wrapper {
  position: relative;
  box-shadow: 0px 3px 2rem rgba(143, 157, 176, 0.57);
  border-radius: 1rem;
  height: max-content;
}

.no-shadow {
  box-shadow: none;
}

.Card--wrapper--transparent {
  background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
  box-shadow: none;
  color: var(--white);
}

.Card--Title--transparent * {
  color: var(--white);
}

.Card--wrapper--white {
  background: var(--white) 0% 0% no-repeat padding-box;
}

.Card--content {
  overflow: hidden;
  border-radius: 1rem;
  color: initial;
}

.Card--content--transparent {
  background: none;
  color: var(--white);
}

.Card--content--transparent-title {
  box-shadow: 0px 3px 2rem rgba(143, 157, 176, 0.57);
}

.Card--content--white {
  background: var(--white) 0% 0% no-repeat padding-box;
}
